const LocationReviewsData = [
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Kyle Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Melissa Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sandra G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Linda Mckenzie",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Harry C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Krystal Valdez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Angela Robinson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cynthia W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Alice Coleman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Miguel C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Gabriel Frank",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patrick J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Ariana Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Elizabeth C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Meredith Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "John Manning",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Denise Long",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nathan P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Tracey Rivera MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nicole J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Brandon Vincent",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jorge C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Miranda Berry",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stacie A.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Darrell White",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Benjamin S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Franklin Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Heather G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Eric Martin",
        "service_type": "Garage Door Installation",
        "tech_name": "Chad W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Andrea Lewis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Zachary H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Nathan Griffin",
        "service_type": "Garage Door Installation",
        "tech_name": "Angela W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Jodi Torres",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joshua E.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Catherine Fisher",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joel B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "William Stone",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Toni Dyer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Megan B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Chad Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Richard J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Kaylee Casey",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Blake S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Kristina Tran",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Derek Perry",
        "service_type": "Garage Door Insulation",
        "tech_name": "Aaron K.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Matthew Mcdonald",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Angela S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Sheila Small",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Zachary D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Samantha Green",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Elizabeth K.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Greg Harrison",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Keith B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Billy Hernandez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffrey L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Aaron Parks",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kathryn G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Matthew Boyd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Harold Y.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Williamson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Anthony Peters",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Candace Mills",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kathy F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Tammy Beck",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shannon H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Craig Soto",
        "service_type": "Garage Door Off Track",
        "tech_name": "Abigail M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Heather Baker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Noah C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "George Park",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kyle H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Bianca Marshall",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Courtney M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Tracy Fry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melissa S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Aaron Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael A.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Kimberly Leonard",
        "service_type": "Garage Door Repair",
        "tech_name": "Emily C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Brandon Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Andrew T.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Tiffany Collins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Mark Patterson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brooke P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Harold Yang",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cassandra P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Rebecca Camacho",
        "service_type": "Garage Door Opener",
        "tech_name": "Sergio M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Chase Nelson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shawna P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Donna Farmer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Roberto H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Michael Krueger",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Karen P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Calvin Richardson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sandra M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Melissa Vasquez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melvin K.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Steven Lee",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Gina Oneill",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gregory G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Lynn Walker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "William Perry",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sarah M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Jenny Fuentes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Adrian S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Matthew Edwards",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Ryan Fuentes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ashley B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Brian Sanders",
        "service_type": "Garage Door Services",
        "tech_name": "Diane H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Erin Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Shelly Rhodes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Joseph Silva",
        "service_type": "Garage Door Repair",
        "tech_name": "Jonathan G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "David Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Wesley W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Steven Thompson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Matthew C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Christy Yates",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Laura Huang",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Rodney Ayala",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Joseph Rivera",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephanie M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Courtney Haley",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Patricia Marshall",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christian H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Gregory Reynolds",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christina T.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Scott Sims",
        "service_type": "Garage Door Installation",
        "tech_name": "Matthew G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Tamara Torres",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Vanessa Stanley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Denise H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Barbara Evans",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Monica C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Kim Bell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James V.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Anderson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Karen M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Tracey Allen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Caitlin R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "William Jones",
        "service_type": "Garage Door Services",
        "tech_name": "Holly D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Gerald Nguyen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Curtis P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Megan Collins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Douglas J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Lee Koch",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kevin F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Brandon Martin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Whitney K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jessica Berg",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Taylor Schultz",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Teresa Thompson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Timothy S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Joseph Jenkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Catherine H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Dunn",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Hannah R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Stacey Mack",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mary S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Jessica Hernandez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sherri J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Mr. Robert Kelley",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tony A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Regina Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Marco K.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Michelle Mccarty",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Jill Walker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Jamie Baker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kevin E.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Patrick Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kevin M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Thomas Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Victor G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "James Lin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kayla Y.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Maria Diaz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kayla J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Shannon Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brandi P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Kirsten Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Susan W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Kelly Serrano",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelly H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Edward Freeman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Paul T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Barbara Wilson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Megan B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Angela Parker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gregory K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Kyle Anderson DDS",
        "service_type": "Garage Door Installation",
        "tech_name": "Tracy M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Hannah Ross MD",
        "service_type": "Garage Door Installation",
        "tech_name": "Gregory W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Bryan Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Dylan W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Victoria Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard F.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Joshua Mitchell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Gregory Reyes",
        "service_type": "Garage Door Installation",
        "tech_name": "Tony T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "David Floyd",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dawn P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Brian Stewart",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Charles Oconnor",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kendra S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "James Pham",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Brett Boone",
        "service_type": "Garage Door Repair",
        "tech_name": "Austin R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Sabrina Blankenship",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mike W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Paul Alexander",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stacey C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Regina Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brooke J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Andrew Briggs",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Patrick Petty",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Spencer Y.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Nicole Green",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kenneth M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Kelly Salazar",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patrick B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Edward Mcpherson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jaclyn H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Jake Velez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Krista R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "David Perez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Dr. John Myers",
        "service_type": "Garage Door Repair",
        "tech_name": "Joyce S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Emily Hanson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Kenneth Graves",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Samantha D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Angela Medina",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kathryn V.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Stephen Watkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alexis C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Arthur Oliver",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kenneth T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Stephanie Moreno",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christine W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Kristopher Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Elaine Stewart",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Watson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Connie Dodson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Kevin Barton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sharon B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Allison Henderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Paul R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Bradley Clark",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Benjamin T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Courtney Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dawn M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Lisa Townsend",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rebecca G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "April Sanchez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jonathan T.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Daniel Hamilton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Megan H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Steven Cantrell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michelle S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Isabella Collins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Leah D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Christopher Ramos",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sheila R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Julie Elliott",
        "service_type": "Garage Door Opener",
        "tech_name": "Patricia H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Lisa Burns",
        "service_type": "Garage Door Installation",
        "tech_name": "Linda L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Carrie Gonzalez",
        "service_type": "Garage Door Opener",
        "tech_name": "Steven B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Amanda Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher V.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Amanda Reed",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael R.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Gilbert Ford",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christina M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Timothy Fuller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jill E.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Troy Crawford",
        "service_type": "Garage Door Opener",
        "tech_name": "Daniel G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Sheila Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Derek M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Brenda Hughes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Derek S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Michelle Patrick",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Edward S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Rachael Bailey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Craig Dunn",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gail W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Dawn Stewart",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amanda B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Randy Warner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Katie P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Leah Carroll",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Luis Gonzalez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Raymond B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "David Hale",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Amy Matthews",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Joseph Roberts",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Heather M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Lori Sanchez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mark A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Lawrence Black",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Andrew Y.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Adrian Rose",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mike H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Anthony Martin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gerald M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Gina Richards",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stephanie C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Anthony Lynch",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jason A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Shannon Gray",
        "service_type": "Garage Door Insulation",
        "tech_name": "Bradley S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Michael Thomas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anita H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Jessica Hopkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Debra Woodard",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lindsay S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Robinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Craig W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Steven Mcdonald",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeremy S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Dennis Hernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Laura L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Katherine Sanders",
        "service_type": "Garage Door Repair",
        "tech_name": "Danielle P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Edgar Gutierrez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amy M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Seth Cole",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Heather W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Alex Graham Jr.",
        "service_type": "Garage Door Opener",
        "tech_name": "Julie N.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Sarah Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Elizabeth L.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Melanie Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Olivia M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Carol Drake",
        "service_type": "Garage Door Off Track",
        "tech_name": "Douglas Z.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "James Ellis",
        "service_type": "Garage Door Opener",
        "tech_name": "James B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Timothy West",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Catherine Oneal",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Patricia G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Sarah Scott",
        "service_type": "Garage Door Installation",
        "tech_name": "Sheila P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Jonathan Hall",
        "service_type": "Garage Door Insulation",
        "tech_name": "Theodore F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Kenneth Gonzalez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kristy C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Michael Morris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kristen N.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Adam H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Dustin Vargas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Derrick W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Lynn Gutierrez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Antonio K.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Micheal Contreras",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kevin G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Taylor Oneill",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Derrick S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Courtney Leonard",
        "service_type": "Garage Door Installation",
        "tech_name": "Joshua H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Linda Cline",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jasmine D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Joshua White",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Peter B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Brittney Murphy",
        "service_type": "Garage Door Services",
        "tech_name": "Trevor D.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Michelle Moore",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samantha C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Wendy Wells",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Paul V.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Theresa Diaz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Felicia A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Lisa Harris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Emily H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Stephanie Avery",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Courtney Caldwell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jason M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "James Cole DVM",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carlos W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Michael Owens",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mark O.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Martin Tyler",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erin H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Justin Mitchell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Johnny Colon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donald H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Randy Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jennifer K.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Allison Hurst",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rebecca R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "John Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Zachary C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Jessica Alvarez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shelley F.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Brandon Mercado",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tara H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Melissa Edwards",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Richard M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Patricia Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Catherine Escobar",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Dawn S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Joseph Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dennis P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Amanda Ball",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Larry T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Nancy Sanchez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sherry O.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Holly Castro",
        "service_type": "Garage Door Repair",
        "tech_name": "Paul M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Jo Cisneros",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Benjamin B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Lisa Martinez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adam A.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Heather Gomez",
        "service_type": "Garage Door Opener",
        "tech_name": "Lisa H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Mary King",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Theresa S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Taylor Carpenter",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Robert Chase",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Peggy C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Miss Samantha Aguirre",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Danielle Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Allison B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Shawn Ortiz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Jonathan Davidson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Ryan Booker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kerry R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "James Cooper",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Aaron Y.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "David Walker",
        "service_type": "Garage Door Installation",
        "tech_name": "Randall S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Daniel Rhodes",
        "service_type": "Garage Door Opener",
        "tech_name": "Tracey M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Andrea Stewart",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gregory J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Maria Ruiz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Mary Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Gabriela Hood",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Benjamin G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Michael Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nathan R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Lisa Clark",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Justin C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Jesse Riley",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Julia O.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Jordan Carrillo",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Evan C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Kristina Arnold",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sydney C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Erin Moyer",
        "service_type": "Garage Door Services",
        "tech_name": "James B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Anna Dunn",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alexandra D.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Pamela Olson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Samuel E.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Nicole Moore",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Benjamin D.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Brian Murphy",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cassandra O.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Eric Durham",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tyler H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Daniel Miller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Monique M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "William Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Briana B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Donna Baker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gina H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Lewis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "John Stone",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Samantha Hall",
        "service_type": "Garage Door Repair",
        "tech_name": "Krystal J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Natasha Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Linda S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Tyler Sherman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph O.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Jesse Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lisa M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Lisa Taylor",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicholas C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Jessica Nelson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kristen G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Brian Coleman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lisa J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Renee Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Deborah R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Kyle Hogan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stephanie B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Craig Franklin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "William Kirk",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Gina S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Anna Reed",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alan C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Traci Haney",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Corey W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Scott Page",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Timothy Gray",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Kelly Wall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Noah L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Cheryl Henry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Richard R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Patricia Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patricia R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Julie Bell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jessica Obrien",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cody J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "William Davis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Devin G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Stephanie Weaver",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nancy H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Tami Douglas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Sandra Woods",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael K.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Eric Spears",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jerry A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Charles Simpson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steve B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "John Robinson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amber T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Jerry Banks",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kimberly H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Joshua Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristen J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Sarah Frazier",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melinda C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Tammy Miller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Beverly N.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Mr. Mathew Lloyd",
        "service_type": "Garage Door Services",
        "tech_name": "Kelly T.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Dustin Klein",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rhonda M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Samantha Young",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Justin G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Dustin Ramsey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Austin S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Jeanette Anderson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jared M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Katherine Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Megan D.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Zachary Wolfe",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jonathan H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Mary Rodriguez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karen W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Marie Walker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Erin L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Mary Wheeler",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Laura Stewart",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Abigail O.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Ann Flores",
        "service_type": "Garage Door Insulation",
        "tech_name": "David P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Paul Harris",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cynthia N.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Stephanie Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Dr. Veronica Pena",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shawn R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Dave Wang DDS",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Eric P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Brittany Coleman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Teresa Farmer",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Yolanda L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Todd Archer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rebecca S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Ware",
        "service_type": "Garage Door Opener",
        "tech_name": "Carol M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Kenneth Aguilar",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Debra Harris",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael E.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Brandi Park",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Fernando M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Wendy Rocha",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Danny B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Briana Nelson",
        "service_type": "Garage Door Installation",
        "tech_name": "Kyle M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Marcus Taylor",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amanda R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Sue Bowers",
        "service_type": "Garage Door Opener",
        "tech_name": "Albert P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Donna Lynch",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica O.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Miranda Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Theresa Rogers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gregory B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Ashley Craig",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ian M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Rebecca Willis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erin M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Pamela Mitchell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ellen T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Shawn Solis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Diane S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Meagan Zhang",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kimberly B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Stephen Campos",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew N.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Nicholas Hartman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jaime S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Pamela Tucker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Victoria T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Lee Buck",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Don D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Karen Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carlos W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Craig Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Pamela B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Beverly Aguilar",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Raymond O.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Sheryl Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brianna M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Gary Osborne",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Maria Willis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Scott P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Jonathan Lindsey",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher A.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Scott Harding",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kathleen G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Meredith Massey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Oscar R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "David Lopez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ann H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Bryce Cruz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Susan R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Daniel Warren",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Luis W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Ryan Caldwell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Adam Mills",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jessica P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Christopher Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gina H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Austin Bentley",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle V.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Brenda Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "Shawn J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Bennett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Ryan Gutierrez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Curtis Page",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Trevor B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Dominique Mosley",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alexandra Y.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Gregory Willis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joshua W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Jessica Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Thomas L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Ms. Helen Jarvis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Sarah Monroe",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michelle B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Dylan Scott",
        "service_type": "Garage Door Opener",
        "tech_name": "Gina T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Donald Griffith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Angela B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Pedro Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "David Taylor",
        "service_type": "Garage Door Opener",
        "tech_name": "Megan E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Ashley Grant",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Leah A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Haley Owen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Donald W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Rachel Ortiz",
        "service_type": "Garage Door Installation",
        "tech_name": "Virginia G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Rebecca Hardy",
        "service_type": "Garage Door Services",
        "tech_name": "Jason O.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Amanda Rivera",
        "service_type": "Garage Door Services",
        "tech_name": "Laurie P.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Brian Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tracey A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Lori Forbes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nicole M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Christopher Watkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dana M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "George Oliver",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jimmy S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Edwin Wilcox",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Ms. Amanda Chandler",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kenneth M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Steven Crawford",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Corey Parks",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cheryl S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Melissa Mccoy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alyssa B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Hannah Day",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Riley W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Krista Palmer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Carmen G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Derek Gutierrez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Francisco G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Roger Ramirez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Megan Blake",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rita S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Jamie Spears",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Yolanda W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Donald Hill",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Valerie Y.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Mrs. Natalie Santos PhD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Crystal C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Kevin Mueller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bradley H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Moore",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gerald M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Jack Anderson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Margaret K.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Marilyn Bell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Courtney Jackson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lisa P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Wendy Ramos",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Benjamin M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Martha Burns",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Aaron R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Donna Robbins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rebecca O.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Terri Heath",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephanie K.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Katherine Griffin",
        "service_type": "Garage Door Repair",
        "tech_name": "Philip W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Ashley Branch",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Karen G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Kevin Sullivan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sarah R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Ronnie Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Thomas R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Erika King",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Anna W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Nancy Crawford",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joshua C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Veronica Parker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Timothy W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Scott Patterson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Crystal L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Steven Wilson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nathan M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Matthew Dixon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Leslie K.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "David Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Derek L.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Luke Castro",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kimberly C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "William Moss",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Roger T.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Ryan Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Keith K.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Christina Paul",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Elizabeth G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Meghan Castillo",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Juan A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Ryan Mclaughlin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tonya D.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Noah Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Bailey Hurley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Scott Bradford",
        "service_type": "Garage Door Repair",
        "tech_name": "William A.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "William Gibson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Kathryn Ayala",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alan M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Bobby Lopez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Troy E.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Brandon Walters",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Emily A.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Kimberly Mccann",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandon M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Andrew Schmidt",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Eric F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Erickson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marcus S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "John Walter",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carrie G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Tiffany Matthews",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melissa L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Jeffery Brown",
        "service_type": "Garage Door Installation",
        "tech_name": "Ruben M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Kayla Guerrero",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mallory B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Diana Mcgee",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ryan J.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Derek Henry",
        "service_type": "Garage Door Services",
        "tech_name": "Tracy T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Henry James",
        "service_type": "Garage Door Repair",
        "tech_name": "Regina B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Heather Brooks",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carrie W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Stacey Delgado",
        "service_type": "Garage Door Repair",
        "tech_name": "Alexandra A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Donald Mills",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Timothy R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Brenda Maynard",
        "service_type": "Garage Door Services",
        "tech_name": "Julia J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Walter Burns",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica A.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Veronica Kline",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Elizabeth K.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Raymond Barrett",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kendra H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Hannah Hernandez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicole P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "John Leblanc",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Virginia W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "John Ochoa",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Randy A.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Cindy Riley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Madison Combs",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James O.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Stephen Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Debra P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Mr. Bruce Christensen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Felicia T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Joshua Freeman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kaitlin G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Antonio Schmidt",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Sandra Guzman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Meagan F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Molly Ross",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Thomas D.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Kerri Smith DDS",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "John W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Zachary Roberts",
        "service_type": "Garage Door Insulation",
        "tech_name": "Grace J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Kimberly Duncan",
        "service_type": "Garage Door Repair",
        "tech_name": "Christine W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Matthew Ramirez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Martin S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Kathy Cohen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Teresa M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Diana Herrera",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Natasha M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Michele Holmes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Renee W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Ronnie Taylor",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bethany S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "David Turner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Susan Fisher",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Allison D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "John Braun",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lori D.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "David Bender",
        "service_type": "Garage Door Repair",
        "tech_name": "Garrett D.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Samantha Austin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anthony C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Jimmy Bailey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Monica H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Sandra Flynn",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kaitlyn J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Debbie Martin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jasmine M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Casey Young",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alice G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Cheryl Russell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mary P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Mark Meza",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Thomas Gomez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Briana S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Jamie Burgess",
        "service_type": "Garage Door Opener",
        "tech_name": "Tony W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Joseph Perez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Linda F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Shannon Griffith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jasmine R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Sean Stone",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rebecca M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Vincent Oliver",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brittney T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Rachel Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Scott C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Stefanie Byrd",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sabrina H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "William Jackson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Stephen Perez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Courtney B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Ramos",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tyler M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Bobby Chandler",
        "service_type": "Garage Door Services",
        "tech_name": "Tammy H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Sharon Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Timothy T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Shannon Kim",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gregory H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Kristy Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Barbara R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Courtney Bishop",
        "service_type": "Garage Door Services",
        "tech_name": "Anthony B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Christopher Allison",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Evan M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Patrick Green",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melanie M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Kimberly Crosby",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Brad Wiggins",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Victoria D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Jennifer Richards",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joshua J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Margaret Wang",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tracie W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Gregory Lynch",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Marcus H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Jennifer Herring",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Johnny Young",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ronald W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "David Carter",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jimmy M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Tim Oneal",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andre P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Jacob Bryan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Anthony Hill",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephanie M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Alejandro Lee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebecca C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Andrea Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tim H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Richard Henry",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Austin C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Michelle Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Katherine H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Joseph",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mariah A.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Karla Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christina C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Austin Holland",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cody L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Randy Willis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicholas A.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Nicholas Mitchell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amber S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Tyler King",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jamie W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Christina Pennington",
        "service_type": "Garage Door Repair",
        "tech_name": "Cheyenne N.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Jose Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alan B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Brianna Hanson",
        "service_type": "Garage Door Services",
        "tech_name": "Calvin K.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Tiffany Buchanan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samantha C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Patrick Miller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Melissa M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Tiffany Lopez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Teresa S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Sheila Delgado",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Debbie C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Vincent Hansen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jill B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Bianca Collins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "David Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan N.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Jessica Porter",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Jason Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Christian Reynolds",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Bruce N.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Brandon Huffman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brandi G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Stephen Yang",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeffery B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Aguirre MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Sandra Rodriguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Scott Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Elizabeth D.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Mrs. Heather Collins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joshua W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Rogers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Matthew Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Susan S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Dr. Glen Turner",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Kaylee Payne",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Breanna M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Shawn Carlson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Ashley Wright",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Wanda W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Katie Martinez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anna M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "John Cook",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rodney D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Melissa Harris",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Samuel P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Jasmine Parker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laura M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Teresa Sullivan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nina M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Linda Hernandez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Daniel Young",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael N.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Jonathan Jacobs",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sean W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Michael King",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Carrie Thompson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David N.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Timothy Patrick",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alan G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Jennifer Mcfarland",
        "service_type": "Garage Door Services",
        "tech_name": "Kristine J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Barry Dillon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephen T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Steven Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sharon W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Jennifer Jarvis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gregory D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Linda Hamilton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jessica T.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Mary Anderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Travis N.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Mr. Darrell Hernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Mallory B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Brian Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Andrew B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Robert Boyd",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rebecca C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Melissa Wallace",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Crystal G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Colleen Chung",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Darryl R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Cheryl Hernandez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shannon H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Jessica Estrada",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jose S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Michael Morse",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley N.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Rachel Myers",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ronald A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "David Lee",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jaclyn M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Harmon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kevin M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Christina Rogers",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Meagan Mendoza",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "John Craig",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael E.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Steven Davis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Wanda J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Shannon Santiago",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Candace Newton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Christina Anderson MD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Charles M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Rachel Marshall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cynthia W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Collin Anderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Aaron C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Shannon Edwards",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Justin W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Christine Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Allen B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Jacob Walters",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Mr. Greg Hansen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amber G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Kevin Young",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Patty F.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "David Turner",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Donald F.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Matthew Harrison",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alyssa P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Rita Nelson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Chad G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Susan Warren",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Matthew Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sarah G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Ralph Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Gregory Riggs",
        "service_type": "Garage Door Insulation",
        "tech_name": "Isaiah W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Rachel Miller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jill M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Douglas Rivas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Julia L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Brown",
        "service_type": "Garage Door Installation",
        "tech_name": "Alicia R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Jon Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lauren A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "David Stanley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Heather Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Yvonne Chambers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Patricia M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Hunter Gould",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ian C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Robert Porter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tyler K.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Madeline Cunningham",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stefanie L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "William Evans",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Anita Stewart",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joshua S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Thomas Kelley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nancy C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Laurie Mcintyre",
        "service_type": "Garage Door Repair",
        "tech_name": "Jorge R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Dennis Harvey",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Roy M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joseph O.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Kurt Turner",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Angela J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Lance Diaz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sean C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Paul Howell",
        "service_type": "Garage Door Installation",
        "tech_name": "Lydia T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Regina Stein",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cheyenne R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Shelley Shields",
        "service_type": "Garage Door Services",
        "tech_name": "Morgan S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Colin Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Karina C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Margaret White",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Tracy Cole",
        "service_type": "Garage Door Insulation",
        "tech_name": "Larry B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Michael Cook",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joseph P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Mary David",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Aaron M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Erica Chavez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kelly C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "David Morrison",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Sally Collier",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jessica O.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Susan Griffith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kathleen P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Frank Clark",
        "service_type": "Garage Door Installation",
        "tech_name": "Gina J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Andrea Sullivan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jessica N.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Troy Hampton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Julie Lang",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kevin S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joshua H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Carrie Morris",
        "service_type": "Garage Door Services",
        "tech_name": "Julie P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Charles Guzman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Sabrina Jordan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Connor S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Aaron Harmon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Austin W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Jamie Moreno",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Diana M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Travis Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Candice M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Barbara Powers",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Angela J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Donald Tate",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mackenzie H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Billy Branch",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ryan C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Jennifer Savage",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristin C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Janet Griffin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Caitlin C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Brenda Kerr",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Julie P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Lori Macias",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sarah M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Martin White",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amy S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Jo Morton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Bridget T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Terry Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shelley Z.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Dr. Cassandra Wilson DDS",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Nicholas Harris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jorge H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Catherine Brooks",
        "service_type": "Garage Door Opener",
        "tech_name": "William B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Kelly Wright",
        "service_type": "Garage Door Services",
        "tech_name": "Robert R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Christina Gibson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gregory M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Danielle Shelton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Theresa J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Gerald Woods",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kenneth G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Kevin Shah",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Maria C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Jason Hill",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dustin W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Vincent Parker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anthony S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Jesse Poole",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gabriel Y.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Sheri Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christine H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Bruce Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Colleen G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Richard Rice",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Justin Meadows",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Kimberly Gonzalez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ronald P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Dr. Martin Archer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Madison M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Michelle Elliott",
        "service_type": "Garage Door Installation",
        "tech_name": "Paul G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Christine Tapia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephanie A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Ashley Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shawn W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Zachary Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Raymond Lee DVM",
        "service_type": "Garage Door Repair",
        "tech_name": "Kathy C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Denise Knox",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Eric M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Bruce Evans",
        "service_type": "Garage Door Opener",
        "tech_name": "Victor C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Kristi Maddox",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Samantha R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Leah Ayers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carrie S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Perry Malone",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shawn G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Stephanie Herrera",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Raymond Mccoy",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Katie H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Janet Mcfarland",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Catherine A.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Michael Hernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bryan W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Jason Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Crystal M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Melinda Sherman",
        "service_type": "Garage Door Services",
        "tech_name": "Randy M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Daniel Lee",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Thomas Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Michelle Stone",
        "service_type": "Garage Door Insulation",
        "tech_name": "Benjamin V.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Peter Lester",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Samuel S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Steve Evans",
        "service_type": "Garage Door Installation",
        "tech_name": "Diana S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Rachel Kim",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ellen F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Shirley Barker",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Steven Lopez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Olivia Phillips",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Marc W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Madison Barajas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "George C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Jeffery Stevenson",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Michelle Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "John C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Cindy Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Deborah W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Heather Fernandez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Linda O.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Kimberly Marquez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Susan F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Lisa Gonzales",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Gabriella D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Michael Guerrero",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Megan C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Bell",
        "service_type": "Garage Door Repair",
        "tech_name": "Travis W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "John Weeks",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacob W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Tyler Barron",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Wilcox",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amber D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Joseph Whitaker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christine N.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Dean Garcia",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erica M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Alyssa Montgomery",
        "service_type": "Garage Door Services",
        "tech_name": "Richard M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Michael Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Christopher Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Deborah M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Erica Maldonado",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gavin S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Heather Morris",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Charles Glover",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Michael Graham",
        "service_type": "Garage Door Installation",
        "tech_name": "Carlos W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Melanie Espinoza",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Yesenia M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Stephanie Anderson",
        "service_type": "Garage Door Services",
        "tech_name": "Hannah G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Emily Wolf",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "April L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Dillon Howard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brittany P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Jamie Tucker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kevin M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Kenneth Chandler",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Erin C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Samantha Schultz",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Timothy H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Jessica Farrell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Corey B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Tom Hamilton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kayla B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Catherine Barber",
        "service_type": "Garage Door Insulation",
        "tech_name": "Barbara W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Michael Reid",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sarah H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Ricardo Evans",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Laura O.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Christopher Taylor",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephen S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Julie Espinoza",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Peter C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Sandra Wood",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sharon J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Matthew Hernandez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Hailey Arias MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dawn S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Monica Torres",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jordan A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Willie Howard",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeremy R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Donna Bailey",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Francisco G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Matthew Wheeler",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kyle C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Jonathan Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Donald Ellis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kathryn P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Toni Rodriguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Susan W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Melissa Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Donald W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Zachary Hudson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer A.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Jason Jordan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Marie W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Jessica Butler",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Natalie R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Derek Dunn",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Cynthia Mayo",
        "service_type": "Garage Door Installation",
        "tech_name": "Tanya B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Regina Clements",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew E.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Hunter Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Hannah Hernandez",
        "service_type": "Garage Door Opener",
        "tech_name": "Isaac S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Kevin Yang",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ryan L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "John Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Chris Christensen",
        "service_type": "Garage Door Services",
        "tech_name": "James W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Jake Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Janet J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Denise Kelly",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashlee M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Ms. Brittney Wolfe",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Charles W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Steve Russell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Misty M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Michael Galloway",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Colleen M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Tracy Rogers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lisa M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "William George",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Susan M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Ashley Lewis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas A.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Evan James",
        "service_type": "Garage Door Repair",
        "tech_name": "Jerry L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Christina Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Terri H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Amanda Le",
        "service_type": "Garage Door Opener",
        "tech_name": "Tamara B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Charlene Ochoa",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lucas J.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Ashley Hernandez",
        "service_type": "Garage Door Services",
        "tech_name": "Jacob W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Katherine Bennett",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Karen Hughes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Troy Palmer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brenda S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Joseph Turner",
        "service_type": "Garage Door Repair",
        "tech_name": "Felicia W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Caitlin Adkins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stacey W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Jason Ray",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alexander C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Lydia Cunningham",
        "service_type": "Garage Door Services",
        "tech_name": "Heather S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Christine Garza",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Angel Baker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shannon P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "John Robertson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sue H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Aaron Moreno",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mike J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Kathleen Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Stacey M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Benjamin Salas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christie M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "James Rios",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Becky B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Adam Allen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Raymond T.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Morales",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Raymond Daniel",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shawn L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Colton Thornton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Steven Patton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Mrs. Linda Peterson",
        "service_type": "Garage Door Services",
        "tech_name": "Andrew B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Debra Farmer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Claire D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Tracey Pope",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Samantha H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Arthur Estes",
        "service_type": "Garage Door Repair",
        "tech_name": "David D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Janice Olson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "George Howard",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mario M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Mike Sullivan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Mary Moreno",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christopher J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Jessica Hill",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Brett Ward",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Wendy S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Donald Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kevin J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Ryan Mullins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cesar E.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Jesse Terry",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jodi G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Michael Peterson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Russell W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Kimberly Jennings",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Adriana V.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Benjamin Moore",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rebecca S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Alyssa Irwin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Megan Hernandez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christina S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Richardson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joseph O.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Amanda Glover",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ray B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Jessica Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Robert Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joel S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Patricia Hernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joel K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Nancy Pierce",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kenneth B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Jeffery Mendoza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Angela L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Karen Wilson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Jose Richard Jr.",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicole N.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Robert Herrera",
        "service_type": "Garage Door Opener",
        "tech_name": "Calvin G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Crystal Ross",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Marcus Bauer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rebecca B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Robyn Higgins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alison C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "James Bonilla",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Harold C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Michael Rhodes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Miranda R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Lori Vargas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexander L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Thomas Friedman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tyler W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Brian Alvarez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Courtney D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Brenda Lin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rachel R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Leah Barker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Darrell Yoder",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christopher S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Amy Martinez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Kayla Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffrey B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "John Rojas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dale H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Timothy Gonzales",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Diana Sexton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jasmine S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Tracey Gallagher",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ariel A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Dana Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Virginia H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Jennifer Mclaughlin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kathleen J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Ann Fernandez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kyle L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Jamie Murray",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeffrey M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Christopher Gonzales",
        "service_type": "Garage Door Off Track",
        "tech_name": "Norman M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Mindy Ibarra",
        "service_type": "Garage Door Installation",
        "tech_name": "Jim F.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Brenda Flores",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Benjamin W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Valerie Cameron",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Travis N.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Karla Adkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christine H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Gina Moore",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amy B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Daniel Howell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tammy W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Anthony Chavez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Martin H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Maria Conway",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Megan Gregory",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Theresa N.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Preston Murphy",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nathan V.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Dana Hernandez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nancy R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Brittany Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Chelsea Johnston",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Benjamin W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Nicole Merritt",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shannon W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Nicholas Young",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Megan B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Douglas Mason",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gary S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Jasmine Nichols",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christine A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Justin Estrada",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ellen L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Barbara Riley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Francisco W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Michael Mills",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bryan O.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Brandy Bell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Richard H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Paula Brady",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Elizabeth P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "John Love",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Susan H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Michelle Anderson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joyce D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Raymond Nelson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Richard H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Kimberly Torres",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brianna N.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Mario Stevens",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Jackson Jordan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Caroline Huang",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Corey L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Kelsey Flowers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Deborah S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Krista Mcneil",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Haley B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Nicholas Schneider",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sara S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Joseph Kirk",
        "service_type": "Garage Door Services",
        "tech_name": "Brian G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Harry Walker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joshua L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Daniel Woods",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joshua W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Dennis Lawson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Donald K.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Charles Ramirez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Linda G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Edward Lynch",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Linda R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Jasmine Taylor",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Maureen Allen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Roy Ward",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dorothy P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Marvin Reed",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexandra M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Anthony Sharp",
        "service_type": "Garage Door Insulation",
        "tech_name": "James M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Natasha Kim",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffrey B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "James Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jennifer Conrad MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mikayla W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Brianna Patterson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ronald B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Michelle Cole",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jonathan R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Jason Gray",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashley M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Douglas Wright",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bill S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Terry Fox",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rebecca H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Donna Montoya",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Andrew Arroyo",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tanya L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Scott Gamble",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Melissa C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Lisa Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Caleb K.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Lori Spears",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Elizabeth G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Leonard West",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristina S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Joanna Lynch",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gerald M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Lin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Thomas Hess",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kevin A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Edward Wilkins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Eric Carter",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michele J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Amanda Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kevin W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Jennifer Schultz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lori K.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Alison Boyer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Rodney Bowman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Christopher Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alexander Z.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Lauren Frank",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tami L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Leslie Hester",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Lauren Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Stanley B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Tina Molina",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Sheri Martinez PhD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amy B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Connor Andersen",
        "service_type": "Garage Door Services",
        "tech_name": "Renee M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Michael Fisher",
        "service_type": "Garage Door Off Track",
        "tech_name": "Yolanda P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Amanda Miles",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Thomas L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Samuel Finley",
        "service_type": "Garage Door Insulation",
        "tech_name": "David E.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Chris Burns",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Emily H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Brian Ali",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Michael James MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sarah J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Joshua Tucker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Russell D.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Ashley Decker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Angela Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Charlene P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Scott Cooper",
        "service_type": "Garage Door Repair",
        "tech_name": "Charles T.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Nicole Kim",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tyler R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Miranda Chavez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Glen G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Monica Stewart",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Meredith R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Shawn Aguirre",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amy H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Alison Leblanc",
        "service_type": "Garage Door Installation",
        "tech_name": "Annette K.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Kara M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Brandon Martinez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Maria G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Megan Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Crystal W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Melissa Kennedy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "David Harris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Debra P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Joseph Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Charles A.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Melissa Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sharon M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Andrew Ferrell",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Kevin Mccormick",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lance P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Laura Spence",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Margaret Snyder",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alan C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Kim Sosa",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Thomas C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "James Barnes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "William A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Steven Obrien",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Roberto B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Tamara Hall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Lee Berry",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angela B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Stephen Patel",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Jason Nelson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Teresa R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "John Price",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Donna Pope",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anita R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Eric Rogers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Timothy D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Kathy Hebert",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian K.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Steven Thompson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paige R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Regina Velasquez",
        "service_type": "Garage Door Services",
        "tech_name": "Ronnie M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Nicolas Sanchez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Micheal M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Kathryn Todd",
        "service_type": "Garage Door Installation",
        "tech_name": "Kristin D.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Julia Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Morgan R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Richard Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Ernest H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Nicholas Roberts",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mark M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Eileen Vang",
        "service_type": "Garage Door Off Track",
        "tech_name": "Matthew I.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Nicholas Turner",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Timothy F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Susan Oliver",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Miguel S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Christine Graham",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jonathan W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Jessica Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Michelle Webb",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Aaron Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Susan R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "William Sharp",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Vanessa Beck",
        "service_type": "Garage Door Services",
        "tech_name": "Melvin W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hammond",
        "service_type": "Garage Door Services",
        "tech_name": "Mariah W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Lisa Roberts",
        "service_type": "Garage Door Off Track",
        "tech_name": "Donald S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Todd Walker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Keith F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Andrea May",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Anthony King",
        "service_type": "Garage Door Installation",
        "tech_name": "Ann P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Danielle Becker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "George G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Amber Lara",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Steven Ryan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "April G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Dylan Coleman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Luke Kelley Jr.",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kenneth C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Dylan Burns",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mary C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Anthony Chaney Jr.",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Maria Massey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mikayla J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Shannon Powell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Julia C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Andrea Lee",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Janet V.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Kayla Ayala",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michelle G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Jeremy Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James V.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Glenn Bradley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michelle W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Keith Foster",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David L.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Adam Lewis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kelsey H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Melissa White",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Claire Haynes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Jeffery Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Angela D.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Andrew Burns",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melinda A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Linda Adams MD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christine W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Katelyn Bates",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alicia C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Karen Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alan G.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Mrs. Karen Vazquez DVM",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Randall M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Paula Ramos",
        "service_type": "Garage Door Opener",
        "tech_name": "Catherine H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Mr. David Mccullough",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mark A.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Tommy Ferguson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Trevor L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Dustin Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicholas C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Cole Allen DDS",
        "service_type": "Garage Door Installation",
        "tech_name": "Pamela R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Michael Young",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Stefanie Sellers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Michael Simmons",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christopher O.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Edward Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristy K.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Lisa Porter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amanda M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Katherine Collins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nathan T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Jenny Walsh",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer E.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Christina Houston",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amber M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Christina Deleon",
        "service_type": "Garage Door Services",
        "tech_name": "William T.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Jerry Rose",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Vanessa J.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "William Norris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Linda H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tina G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Mary Walker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Katrina S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Jennifer Navarro",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer R.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Rachael Barry",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Elizabeth P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Angela Skinner",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Veronica K.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Mr. Sean Scott MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Matthew Landry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samuel R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Jessica Kelly",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patricia L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Hannah T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Samuel Pineda",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rachel H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Gary Berger",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angelica S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Alan Bell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Jacob Kennedy",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Susan I.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Justin Drake",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Vincent M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Erin Hill",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christine W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Loretta Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brandon W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Mary Hansen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Bianca Calhoun",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicholas R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "John Bryant",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Crystal L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Tony Harris",
        "service_type": "Garage Door Opener",
        "tech_name": "Theresa G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Bryan Thomas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brian L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Jacobs",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shelia A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Lori Phillips",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Dr. Benjamin Marshall",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Timothy S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Jessica Pruitt",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sean G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Jeffery Wagner",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ryan T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Joseph Gray",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lindsay P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Thomas Colon",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Cynthia R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Lisa Jenkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Tommy Buckley",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Laura Garcia",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Aaron Kelly",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Susan J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hoover",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Mrs. Heather Brooks",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Natalie K.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "April Patterson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Megan H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Sarah Romero",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Victoria S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Edward Clark",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicholas H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Thomas Cline",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Roger G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Timothy Walker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rhonda M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Karen Gay",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Linda Y.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Danielle Parker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Krystal Harrison",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jose L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Kevin Hawkins DVM",
        "service_type": "Garage Door Repair",
        "tech_name": "Dennis M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Wyatt Hicks",
        "service_type": "Garage Door Installation",
        "tech_name": "Angelica R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Lydia Nelson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Robert Vincent",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Hector W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Samantha Hayes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Raymond M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Brandi Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Aaron M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Kelly Norton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Holly Gonzalez",
        "service_type": "Garage Door Services",
        "tech_name": "Sergio H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Daniel Hanson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cynthia M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Andrew Stone",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Michael Wells",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Kevin Woods",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alice C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Michele Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jacqueline M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Barbara Kirby",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Tara Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jamie G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Melissa Hunt",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brandon V.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Michelle Gonzalez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tony E.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Kelli Franco",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ricky B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Kevin Bailey",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Heather M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Benjamin Savage",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Andrea Allen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Zachary S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Rachel Campbell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eric M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Michael Fleming",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Keith F.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Monica Phillips",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stacy C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Virginia Thomas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Zachary Nelson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Linda L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Lauren Freeman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Frank H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Amanda Clay",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gary H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Andrew Kent",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sarah G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Gabriel Davis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tiffany F.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Jay Moore",
        "service_type": "Garage Door Services",
        "tech_name": "Anna W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Richard Paul",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Scott L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Joy Butler",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kim F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Joseph Thompson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Shelly L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Leslie Aguilar",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer F.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "David Haas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Margaret D.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Maria Hughes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Timothy M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Tanya Phillips",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Benjamin S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Timothy Murphy",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Cynthia E.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "John Thomas DDS",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mackenzie B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Dylan Hunt",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kenneth P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Patrick",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laura W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Brandy Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cassandra W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Darren Mitchell III",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Angela R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Sarah Pacheco",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michele C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Nicole Schneider",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Emily R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Joseph Green",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Greg B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Scott Morales",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Gary Walls",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tina A.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Amy Stephens",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cynthia R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Mark Webb",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Trevor Thomas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Erica B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Maria Gallegos",
        "service_type": "Garage Door Services",
        "tech_name": "Diana F.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Robert Hayes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Omar G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Bryan Crawford",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "John B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Kayla May",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael N.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anthony R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Christopher Mann",
        "service_type": "Garage Door Installation",
        "tech_name": "April V.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Allen Duncan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Douglas W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Julian Flores",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Keith M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Crystal Lopez",
        "service_type": "Garage Door Opener",
        "tech_name": "Cathy W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Kimberly Perkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ryan M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Charles Hayes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paul M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Jennifer Schultz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeremy R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Mercedes Hall",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ann A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Aaron Olson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Susan Lee",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Catherine C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Campbell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Cynthia Lewis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brittany N.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Joseph Bennett",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Kiara Campbell",
        "service_type": "Garage Door Repair",
        "tech_name": "Tara L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Daniel Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Julie B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "David Jackson",
        "service_type": "Garage Door Repair",
        "tech_name": "Mariah Y.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Adrian Murphy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jasmine J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Lindsay Thompson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Laura E.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Taylor Arroyo",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tracy W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Wayne Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Christian M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Brandon Garcia",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dean G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Miss Katherine Hawkins MD",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sean P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Laura Ferrell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christina C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Brett Harris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Bradley M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Austin Gay",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Brandon Wu",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sarah D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Lisa Henderson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Juan G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Cynthia Hill",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kelly S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Justin Clayton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brittany S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "James Barry",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rita M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Erika Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa A.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Nancy Boone",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eric T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Terry Morris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adrian M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "John Lewis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Rose Hawkins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Susan W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Sharon Hall",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica V.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Vanessa Rose",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Margaret P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Jay Levy",
        "service_type": "Garage Door Services",
        "tech_name": "Nathan M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Kent Gates",
        "service_type": "Garage Door Off Track",
        "tech_name": "Erica K.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Christopher Wyatt",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Andrea Hernandez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Scott G.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Ryan Fuller DDS",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Yvette E.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Kerry Roberts",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Miguel M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Mandy Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Fred K.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Jacob Solis",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Zachary Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Daniel Jenkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rebecca W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Robert Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Oscar S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Diane Martinez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jacob D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Melissa Wu",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Ashley Cooper",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gary G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Michael White",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Chelsea L.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Robin Wagner",
        "service_type": "Garage Door Services",
        "tech_name": "Rhonda G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Kimberly Price",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joshua C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Nathan Short",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Susan M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Paul Murphy",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Christine Anderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Stephen Murphy",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher N.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Thomas Doyle",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tyler H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Barry Sims",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Misty S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Danielle Garrett",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Wendy G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Devin Bauer",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Tyler Hanna",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Justin R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Linda Hall",
        "service_type": "Garage Door Repair",
        "tech_name": "Courtney R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Joshua Braun",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cameron W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Kelly Robinson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rebecca G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Todd Wells",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Benjamin P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Francisco Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Sarah Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Elaine B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Caroline Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Brandon Jackson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gina T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Briana Price",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joshua C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Dale Thompson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Matthew Little",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jaime H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Karla Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicholas Z.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Tristan Davis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jonathan C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Deanna Collins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Donald V.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Dana Weaver",
        "service_type": "Garage Door Installation",
        "tech_name": "Donald J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Amanda Holland",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Scott Coleman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nicole K.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Amy Meyer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tammie H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Michaela Hamilton",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacob W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Sylvia Parker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Patrick M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Jennifer Guzman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rebecca J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Samantha Sutton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John O.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Mary Hanson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Maria W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Richard Potts",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lee P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "April Hill",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laurie M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "John Sherman",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Robert Montgomery",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Chelsea T.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Jennifer Payne",
        "service_type": "Garage Door Services",
        "tech_name": "Charles O.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Drew Jordan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Charles Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Craig P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Sara Bailey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Timothy S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Brian Alexander",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Chad R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Jason Dennis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel T.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Stephanie Black",
        "service_type": "Garage Door Installation",
        "tech_name": "William B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Linda Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Aaron R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Kenneth Foley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrew C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Dean Love",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Bethany P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Paul Johnston",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mark W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Michele Jenkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Larry H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Benjamin Murphy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Zachary S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Theodore Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Vincent W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Andrea Ramirez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Heidi F.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Michael Thompson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Donna H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Teresa Schroeder",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Larry A.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "William Herman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Adrian D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Ronald Durham",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Charles Lawson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Joshua Sellers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amber B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Susan Woodward",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Lori Salazar MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jonathan R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Paula Scott",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eddie L.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Laura Harrison",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Terri Jenkins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Miranda Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Monica W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Shelby Carroll",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Carrie Cruz",
        "service_type": "Garage Door Opener",
        "tech_name": "Zachary W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Teresa Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Kathy Patton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cory L.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cristina R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Tracy White",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Patricia F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Brittany Ashley MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jacob H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Meghan Richardson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melissa D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Nicole Gaines",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Alicia Carson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joel M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Rebecca Gregory",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Joseph Warren",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Timothy B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Tiffany Wheeler",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Paige B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Lisa Crawford",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alejandro V.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Heidi Green",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Justin Mitchell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Austin L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Thomas Hopkins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Tara Lynch",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gary S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Kathryn Wall",
        "service_type": "Garage Door Installation",
        "tech_name": "Stephanie B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Margaret Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Samantha M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Gregory Miller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Adam B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Rose",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael E.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Joseph Gallegos",
        "service_type": "Garage Door Installation",
        "tech_name": "Russell B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Fred Clark",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Stacy George",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Karen D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Katherine Ferrell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melissa H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Ryan Henderson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shawn A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Nichole Meyer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jose H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Denise Calderon",
        "service_type": "Garage Door Opener",
        "tech_name": "William T.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Christian Blevins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ashley G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Jeff Ramos",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Justin B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Mr. Anthony Kramer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Peggy Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gina L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Christopher Zhang",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brett E.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Thomas Campbell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Danielle B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Jonathon Collier",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alicia M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Jessica Coleman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Randy Roberts",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Mr. Charles Gillespie",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Tina Cherry",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lance R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Mandy Franklin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Angela M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "John Erickson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Julie F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Lisa Martinez MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bridget M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Amanda Holloway",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Calvin G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Johnathan Stafford",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Nicole Mckenzie",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Vernon A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Crystal Miranda",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Whitney C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Jessica Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Adam F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Kayla Gibson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ruth H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Robert Eaton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Corey Wood",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Rebecca C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Kimberly Delacruz MD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tammy N.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Kristin Lloyd",
        "service_type": "Garage Door Services",
        "tech_name": "Juan W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Vanessa Hanson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Wendy P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Dana Weaver",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angie M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Amy Meyer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Eric S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Micheal Adams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Frank C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Eric Decker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kevin O.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Stacie Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "John Garrett",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Breanna A.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Brian Barker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Hannah M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Robin Ramirez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amy B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Evelyn Hanson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Emily B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Brian Barron",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dorothy T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Carolyn Hays",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Morgan H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Mary Wood",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sandra B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "James Dickson",
        "service_type": "Garage Door Services",
        "tech_name": "Veronica A.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Pamela Berger",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Debra W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Charles Sharp",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samuel J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Dr. Ian Clark",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephen H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Dennis Keller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Heidi G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Monique Soto",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Elizabeth G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Jesse Sullivan",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph Y.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Martha Grant",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Eric C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Julie Rhodes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Vanessa V.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Dana Griffith",
        "service_type": "Garage Door Installation",
        "tech_name": "Dwayne C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "David Bailey",
        "service_type": "Garage Door Insulation",
        "tech_name": "Carlos A.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Jessica Miller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael O.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Crystal Hoffman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Theresa R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Karen Hogan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ashley P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Audrey Mullins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alyssa M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "James Ramirez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Taylor Velazquez",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Thomas Schwartz",
        "service_type": "Garage Door Installation",
        "tech_name": "James K.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Lisa Moses",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kirk H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Michelle Gray",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Larry R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Stewart",
        "service_type": "Garage Door Installation",
        "tech_name": "Kristen R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Christopher Delgado",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Tara Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brenda W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Jonathan Cameron",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Samuel J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Jennifer Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joshua P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "John Terry",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Zachary P.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Amanda Allen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rachel G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Erin Jones",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Taylor C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Wendy Cox",
        "service_type": "Garage Door Insulation",
        "tech_name": "Charlotte N.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Cynthia Leach",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Steven Harrington",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mallory S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "David Mitchell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Jonathan Flowers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Willie T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Richard Acosta",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Marilyn R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Alfred Dalton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tracy H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Leroy Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kevin R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Joshua Benton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Charles B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "James Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joel S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Thomas Watson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Margaret M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Christina Bush",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Todd Baker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joseph G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Theresa Kennedy",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephanie H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Connor Russo",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christina P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Marissa Johnston",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sara J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Melissa Farley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Raymond B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Crystal Shaw",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Destiny H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Amanda Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "Jared C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Deanna Kirby",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jason M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Amanda Frank",
        "service_type": "Garage Door Insulation",
        "tech_name": "Max E.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Aaron Herrera",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shawn D.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Matthew Webb",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Donald L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Amber Baird",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Wendy L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Zachary Terrell",
        "service_type": "Garage Door Repair",
        "tech_name": "Ryan S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Kendra Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Allison Trujillo",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David Y.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Adam Scott",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexander W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "George Orr",
        "service_type": "Garage Door Installation",
        "tech_name": "Barbara H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Pamela Wilson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jason M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Troy Bryant",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Emily L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Christopher Clark",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gregory W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Andrea Whitaker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sara T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Deborah Thompson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Chris Mitchell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Mrs. Natasha Colon MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Karen H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Lori Martinez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tina E.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Brian Davis",
        "service_type": "Garage Door Opener",
        "tech_name": "Kristin R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Ronald Mercado",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Daniel Tran",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sara S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Emily Ford",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alyssa G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Steven Hughes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephanie S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Sandy Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Cory B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Pamela Yates",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Ricky West",
        "service_type": "Garage Door Repair",
        "tech_name": "Haley E.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Joanne Barton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dean P.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Collins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Hannah H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Valenzuela",
        "service_type": "Garage Door Repair",
        "tech_name": "Aaron G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "William Huffman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Autumn C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Antonio Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donna T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Dr. Jason Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeremy H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Jonathan Martinez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Samantha S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Maria Davis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anthony J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Ariel Luna",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua F.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Christopher Rivera",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mark H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Stephen Pope",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Virginia R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Theresa Cole",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brandi G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Christopher Anderson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mark H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Robert Lewis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Amy Mclaughlin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jonathan L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Emily Patterson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kari W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Garrett Lee",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Michelle Wood",
        "service_type": "Garage Door Installation",
        "tech_name": "Shelly J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Phillip Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Christine Arnold",
        "service_type": "Garage Door Insulation",
        "tech_name": "April A.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Michelle Hensley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ronnie E.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Heidi Hernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Anthony W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Dr. Kyle Robinson PhD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kelsey W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Justin Anderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ryan N.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Dawn Strickland",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Benjamin T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Julie Bishop",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Angela P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Janice Clark",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rebecca P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Watts",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Luis Lawrence",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Paul W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Brandi Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Craig C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Anthony Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrea F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Sharon Mendez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joseph S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Bianca Sexton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tracey F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Dominic Ryan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Emily S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Carla Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alan J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Brianna Warren",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kimberly H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Justin Russell",
        "service_type": "Garage Door Services",
        "tech_name": "Amanda F.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Francisco Robertson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Scott Waters",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Andrea Villegas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicholas W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Mr. Anthony Castillo",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stephanie G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Melissa Barton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alicia A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Danielle Briggs",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Donna S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Timothy Ward",
        "service_type": "Garage Door Repair",
        "tech_name": "Steve L.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Alejandro Rivas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joanne A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Danny Lopez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ryan W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Scott Cunningham",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Katherine B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Dr. Lisa Vance",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Kelli Matthews",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica T.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Philip Griffith",
        "service_type": "Garage Door Installation",
        "tech_name": "Julie M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Linda Lawson",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Reyes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jordan B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Deborah Ware",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Leslie Ellis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Sarah Henderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Anthony B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Dr. Chad Shah",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kenneth F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Lisa Garcia",
        "service_type": "Garage Door Repair",
        "tech_name": "Zachary V.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Alison Lam",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Wyatt J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Brian Mendoza",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jill L.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Latoya Rivera",
        "service_type": "Garage Door Installation",
        "tech_name": "Cathy M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "David Cobb",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Justin Turner PhD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeremy R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Richard Rodriguez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Lauren Bennett",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Mallory Carney",
        "service_type": "Garage Door Services",
        "tech_name": "Louis L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hayes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Laura Bullock",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Peggy P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Robert Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mark C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Michelle Rodriguez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rachel W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Jessica Taylor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Nathaniel Nelson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy K.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Anna Peck",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Martin G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Dawn Hayes",
        "service_type": "Garage Door Installation",
        "tech_name": "Cindy S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Tammie York",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Julia M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Benjamin Fisher",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carlos K.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Anita Sutton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melissa H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Ashley Berry",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Michael Owens",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Richard D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Derek Mahoney",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jonathan S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Chloe Hernandez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Donald E.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Susan Fisher",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Emily S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Erin Herrera",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kenneth F.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Mary Leon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steven R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Beverly Greene",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ryan W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Katelyn Sellers",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jose G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Nicholas Vasquez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Anthony G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Tyler Duran",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kayla W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Eric Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anna C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Rachel Nguyen",
        "service_type": "Garage Door Opener",
        "tech_name": "Katherine W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Jon Dillon",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela N.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Peggy Gonzalez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Robert Jackson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nichole W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Daniel Petty",
        "service_type": "Garage Door Off Track",
        "tech_name": "Debra S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Jennifer Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Janice Perez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jenny B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Joseph Beard",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Wendy Reed",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tracy C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Brian Parsons",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laura E.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Thomas Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Lance Holland",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cassandra R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "John Jordan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laura D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Kimberly Ali",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bryan J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Brian Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gloria R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Jennifer Valencia",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cassandra W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Sherry Bradford",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alex G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "James Roberts",
        "service_type": "Garage Door Insulation",
        "tech_name": "Anthony R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Martin Navarro",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gabriel W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Amy Gates",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Linda J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Jorge Mason",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kathryn L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Laura Conway",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Harold M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Steve Baker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Eric P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Christine Castillo",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Amy Wolf",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrea M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "John Hatfield",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kaitlyn M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Savannah Acevedo",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alexandria J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
